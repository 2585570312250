export const addClothingItem = (state, item) => {
  state.clothingItems.push(item)
}

export const updateClothingItem = (state, payload) => {
  let index = state.clothingItems.findIndex(clothingItem => clothingItem.id === payload.id)
  Object.assign(state.clothingItems[index], payload.data)
}

export const removeClothingItem = (state, clothingItem) => {
  let index = state.clothingItems.findIndex(item => item.clothing_item_id === clothingItem.clothing_item_id)

  state.clothingItems.splice(index, 1)
}