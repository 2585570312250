const arrayFilters = [
  'partners'
]

export const getFilters = (state) => {
  return state.filters
}

/**
 * {
   *     filterTitle: String (not null),
   *     filterItem: Object (nullable),
   *     filterType: String,
   *     grouped: true/false/null
   * }
 */

export const getArrayForQuickFilters = (state) => {
  let result = []

  arrayFilters.forEach((filterType) => {
    // In case we have less than four items
    // they are all listed in filters
    if (state.filters[filterType].length <= 4) {
      state.filters[filterType].forEach((filterItem) => {
        result.push({
          filterTitle: filterItem.title,
          filterItem,
          filterType
        })
      })
      // Otherwise we group them together
    } else {
      let filterTitle = state.filters[filterType].slice(0, 4).map(filter => filter.title).join(', ')
      filterTitle += `... и еще ${state.filters[filterType].length - 4}`

      result.push({
        filterTitle,
        filterType,
        grouped: true
      })
    }
  })

  result.forEach((item, index) => {
    item.id = index + 1
  })

  return result
}

export const getLook = (state) => {
  return state.look
}

export const isLookInStudio = (state) => {
  return lookId => {
    return state.look.id === lookId
  }
}
