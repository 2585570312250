import store from '@/vuex/index'
import errorCodes from '@/constants/errorCodes.js'
import profileTransformer from '@/services/transformers/profileTransformer'

export const authenticated = () => {
  return store.getters['auth/authenticated']
}

export const user = () => {
  return store.getters['auth/currentUser']
}

export const registerEphemeralUser = async () => {
  const data = {
    engine: 'ephemeral',
    long_live: true,
    credentials: {},
    meta_data: {
      test: true
    }
  }

  const response = await axios.post('authorize/register', data)

  console.log(response)

  return response.data
}

export const register = data => {
  if (!data.profile) {
    data.profile = {}
  }

  data.profile.gender = 'female'
  data.profile.meta_data = {
    test: true
  }

  return axios.post('authorize/register', JSON.stringify(data))
}

export const confirmEmail = data => {
  return axios.post('authorize/confirm', JSON.stringify(data))
}

export const login = data => {
  return axios.post('authorize/auth', JSON.stringify(data)).then(response => {
    return {
      ...response.data,
      user: profileTransformer(response.data.user)
    }
  })
}

export const logout = () => {
  // Log out from Google Plus
  gapi.auth2.getAuthInstance().signOut()

  store.dispatch('auth/clearAuthInfo')
}

export const requestRestore = data => {
  return axios.post('authorize/restore', JSON.stringify(data))
}

export const restore = data => {
  return axios.patch('authorize/restore', JSON.stringify(data))
}

export const getAccessToken = () => {
  return store.getters['auth/accessToken']
}

export const refreshTokens = () => {
  return axios.post('authorize/refresh', { expire_token: store.getters['auth/expireToken'] }).then(response => {
    store.dispatch('auth/setAuthorizationTokens', {
      accessToken: response.access_token,
      expireToken: response.expire_token
    })
  })
}

/**
 * Get user data by token from an invitation link.
 *
 * @param token
 * @returns Promise
 */
export const getUserByInviteToken = token => {
  return axios.get(`invites/${token}`).then(response => response.data)
}

export const loginViaVkontakte = callbacks => {
  let engine = 'vkontakte'
  let permissionsBitMasks = {
    email: 4194304,
    friends: 2,
    photos: 4
  }

  let permissionsBitMasksSum = Object.values(permissionsBitMasks).reduce((previousValue, currentValue) => {
    return previousValue + currentValue
  })

  VK.Auth.login(response => {
    if (!response.session) {
      callbacks.onClose()

      return
    }

    loginViaSocialNetwork(getSocialNetworkAuthenticationData(engine, response), callbacks)
  }, permissionsBitMasksSum)
}

export const loginViaFacebook = callbacks => {
  let engine = 'facebook'

  FB.login(response => {
    if (!response.authResponse) {
      callbacks.onClose()

      return
    }

    loginViaSocialNetwork(getSocialNetworkAuthenticationData(engine, response), callbacks)
  }, {
    scope: 'user_birthday, user_photos, user_friends, user_gender, email, instagram_basic, public_profile'
  })
}

export const loginViaGooglePlus = callbacks => {
  let engine = 'google_plus'
  let authInstance = gapi.auth2.getAuthInstance()

  if (authInstance.isSignedIn.get()) {
    loginViaSocialNetwork(getSocialNetworkAuthenticationData(engine, authInstance.$currentUser.get()), callbacks)

    return
  }

  authInstance.signIn().then(user => {
    loginViaSocialNetwork(getSocialNetworkAuthenticationData(engine, user), callbacks)
  }).catch(error => {
    if (error.error !== 'popup_closed_by_user') {
      return
    }

    callbacks.onClose()
  })
}

// TODO: Is it the best solution? It violates Open/Closed principle from SOLID but corresponds to the DRY principle
function getSocialNetworkAuthenticationData(engine, data) {
  let authenticationData = {
    engine
  }

  switch (engine) {
    case 'vkontakte':
      authenticationData.accessToken = data.session.sid
      authenticationData.userId = data.session.mid
      break

    case 'facebook':
      authenticationData.accessToken = data.authResponse.accessToken
      authenticationData.userId = data.authResponse.userID
      break

    case 'google_plus':
      authenticationData.accessToken = data.getAuthResponse().id_token
      authenticationData.userId = data.getId()
      break
  }

  return authenticationData
}

function loginViaSocialNetwork({ engine, accessToken, userId }, callbacks) {
  let data = {
    engine,

     credentials: {
      access_token: accessToken,
      user_id: userId
    },

     long_live: true
  }

  // TODO: Probably make a discrete function that checks if the user is registered or not
  login(data)
    .then(response => {
      callbacks.onSuccess(response)
    })
    .catch(error => {
      // TODO: Move error codes to constants
      if (error.response.data.error_code === errorCodes.authInvalid) {
        registerViaSocialNetwork(data, callbacks)

        return
      }

      callbacks.onFail(error)
    })
}

function registerViaSocialNetwork(data, callbacks) {
  register(data)
    .then(response => {
      callbacks.onSuccess(response)
    })
    .catch(error => {
      callbacks.onFail(error)
    })
}
