const storeVersion = '0.0.1'

export default function(store) {
  store.subscribe((mutation, state) => {
    let localStorageState = localStorage.getItem('vuexState') ?
        JSON.parse(localStorage.getItem('vuexState')) : null

    if (localStorageState && localStorageState.version != storeVersion) {
      localStorage.removeItem('vuexState')
    }

    let vuexState = {
      version: storeVersion,
      auth: {
        accessToken: state.auth.accessToken || null,
        expireToken: state.auth.expireToken || null,
        currentUser: state.auth.currentUser || null,
        authenticated: state.auth.authenticated || null,
        invitedUser: state.auth.invitedUser || null
      },
      studio: {
        filters: state.studio.filters,
        look: state.studio.look
      },
      cart: state.cart || null,
      orders: state.orders || null
    }

    localStorage.setItem('vuexState', JSON.stringify(vuexState))
  })
}
