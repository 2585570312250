export default {
  643: '₽',
  840: '$',
  978: '€',
  826: '£',
  36: '$',
  398: '₸',
  933: 'Br',
  980: '₴'
}
