import store from '@/vuex/index.js'

const getLocale = () => {
  let locale

  const defaultLocale = 'en_US'

  if (store.getters['auth/authenticated']) {
    locale = store.getters['auth/currentUser'].locale || defaultLocale
  } else {
    if (localStorage.getItem('locale')) {
      locale = localStorage.getItem('locale')
    } else {
      locale = defaultLocale
    }
  }

  return locale
}

export default getLocale
