export default {
  // Unknown error on server (needs investigation)
  unknown: 0,

  // Unallowable request. For example, DELETE for the resource that ony allows GET & POST
  notAllowed: 1,

  // Request is correct and is in the specification but is not realized or is switched off at the moment
  notRealized: 2,

  // Incorrect request structure. For example, empty body when it should contain JSON
  invalidStructure: 3,

  // Invalid param value
  invalidParamValue: 4,

  // Invalid data type for the param
  invalidParamType: 5,

  // Authorization data is missing
  authNotProvided: 6,

  // Authorization data is invalid
  authInvalid: 7,

  // Authorization data is expired
  authExpired: 8,

  // Access is forbidden for the current user account
  authForbidden: 9,

  // Resource is not found
  notExist: 10,

  // Error while connecting to an external resource
  externalService: 11,

  // Database error (needs investigation)
  database: 12,

  // Resource with the given parameters already exists
  alreadyExist: 13
}