import { looseEqual, looseIndexOf } from '@/services/utils'

export default {
  model: {
    prop: 'selectedItems',
    event: 'update'
  },

  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },

    selectedItems: {
      required: false
    },

    label: {
      required: false,
      type: String
    },

    multiple: {
      required: false,
      default: false
    },

    keys: {
      required: false,
      default: () => {
        return {
          value: 'value',
          label: 'label'
        }
      }
    },

    canBeEmpty: {
      required: false,
      default: false
    }
  },

  data() {
    return {
      internalSelectedItems: null
    }
  },

  methods: {
    toggle(option) {
      if (this.multiple) {
        let index = looseIndexOf(this.internalSelectedItems, option)

        if (index === -1) {
          this.internalSelectedItems.push(option)
        } else {
          this.internalSelectedItems.splice(index, 1)
        }
      } else {
        if (this.optionSelected(option) && this.canBeEmpty) {
          this.internalSelectedItems = null
        } else {
          this.internalSelectedItems = _.cloneDeep(option)
        }
      }

      this.$emit('update', _.cloneDeep(this.internalSelectedItems))
    },

    optionSelected(option) {
      if (this.multiple) {
        return looseIndexOf(this.internalSelectedItems, option) > -1
      }

      return looseEqual(this.internalSelectedItems, option)
    },

    getOptionLabel(option) {
      return option[this.keys.label]
    },

    getOptionValue(option) {
      return option[this.keys.value]
    }
  },

  watch: {
    selectedItems: {
      handler: function(items) {
        this.internalSelectedItems = _.cloneDeep(items)
      },
      immediate: true,
      deep: true
    }
  }
}