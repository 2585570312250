import { compare } from '@/services/utils'

export default {
  namespaced: true,
  state: {
    clothingCategories: [],
    clothingSubCategories: [],
    currencies: [],
    archetypes: [],
    colorTypes: [],
    figureTypes: [],
    partners: [],
    clothingSizeMetrics: [],
    clothingSizes: [],
    countries: [],
    timeZones: [],
    lookContexts: []
  },
  actions: {
    async loadClothingCategories ({ commit }) {
      const response = await axios.get('handbooks/clothing_categories')

      commit('setHandbook', {
        handbook: 'clothingCategories',
        value: response.data
      })
    },

    async loadClothingSubCategories ({ commit }) {
      const response = await axios.get('handbooks/clothing_subcategories')

      commit('setHandbook', {
        handbook: 'clothingSubCategories',
        value: response.data
      })
    },

    async loadCurrencies ({ commit }) {
      const response = await axios.get('handbooks/currencies')

      commit('setHandbook', {
        handbook: 'currencies',
        value: response.data
      })
    },

    async loadArchetypes ({ commit }) {
      const response = await axios.get('handbooks/archetypes')

      commit('setHandbook', {
        handbook: 'archetypes',
        value: response.data
      })
    },

    async loadColorTypes ({ commit }) {
      const response = await axios.get('handbooks/color_types')

      commit('setHandbook', {
        handbook: 'colorTypes',
        value: response.data
      })
    },

    async loadFigureTypes ({ commit }) {
      const response = await axios.get('handbooks/figure_types')

      commit('setHandbook', {
        handbook: 'figureTypes',
        value: response.data
      })
    },

    async loadPartners ({ commit }) {
      const response = await axios.get('handbooks/partners')

      commit('setHandbook', {
        handbook: 'partners',
        value: response.data
      })
    },

    async loadClothingSizeMetrics ({ commit }) {
      const response = await axios.get('handbooks/clothing_size_metrics')

      commit('setHandbook', {
        handbook: 'clothingSizeMetrics',
        value: response.data
      })
    },

    async loadCountries ({ commit }) {
      const response = await axios.get('handbooks/countries')

      commit('setHandbook', {
        handbook: 'countries',
        value: response.data
      })
    },

    async loadTimezones ({ commit }) {
      const response = await axios.get('handbooks/timezones')

      commit('setHandbook', {
        handbook: 'timezones',
        value: response.data
      })
    },

    async loadLookContexts ({ commit }) {
      const response = await axios.get('handbooks/look_contexts')

      commit('setHandbook', {
        handbook: 'lookContexts',
        value: response.data
      })
    },
  },

  getters: {
    getClothingCategories (state) {
      return (gender = null) => {
        if (gender) {
          return state.clothingCategories.filter(category => {
            return category.gender.toLowerCase() === gender.toLowerCase()
          }).sort((a, b) => {
            return a.ordering - b.ordering
          })
        }

        return state.clothingCategories
      }
    },

    getClothingCategoryById (state) {
      return id => state.clothingCategories.find(category => category.clothing_category_id === id)
    },

    getClothingSubCategories (state) {
      return state.clothingSubCategories.concat().sort((a,b) => {
        return compare(a, b, 'title')
      })
    },

    getClothingSubCategoriesByCategoryId (state) {
      return id => {
        return state.clothingSubCategories.filter(subCategory => {
          return subCategory.clothing_category_id === id
        }).sort((a, b) => {
          return compare(a, b, 'title')
        })
      }
    },

    getClothingSubCategoryById (state) {
      return id => state.clothingSubCategories.find(subCategory => subCategory.clothing_subcategory_id === id)
    },

    getCurrencyById (state) {
      return id => state.currencies.find(currency => currency.currency_id === id)
    },

    getPartners (state) {
      return state.partners.concat().sort((a,b) => compare(a, b, 'title'))
    },

    getPartnerById (state) {
      return id => state.partners.find(partner => partner.partner_id === id)
    },

    getAvailablePartners (state) {
      return state.partners.filter(partner => partner.is_available)
    },

    getCountries (state) {
      return state.countries.concat().sort((a, b) => compare(a, b, 'title'))
    },

    getCountryById (state) {
      return id => state.countries.find(country => country.id === id)
    },

    getTimeZoneById (state) {
      return id => state.timeZones.find(timeZone => timeZone.id === id)
    },

    getFigureTypeById (state) {
      return id => state.figureTypes.find(figureType => figureType.id === id)
    },

    getArchetypeById (state) {
      return id => state.archetypes.find(archetype => archetype.archetype_id === id)
    },

    getColorTypeById (state) {
      return id => state.colorTypes.find(colorType => colorType.color_type_id === id)
    }
  },

  mutations: {
    setHandbook(state, { handbook, value }) {
      state[handbook] = value
    }
  }
}
