// @see https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
export const removeEmptyProps = (obj) => {
  const o = JSON.parse(JSON.stringify(obj)); // Clone source oect.

  Object.keys(o).forEach(key => {
    if (o[key] && typeof o[key] === 'object')
      o[key] = removeEmptyProps(o[key]);  // Recurse.
    else if (o[key] === undefined || o[key] === null || o[key] === '')
      delete o[key]; // Delete undefined and null.
    else
      o[key] = o[key];  // Copy value.
  });

  return o; // Return new object.
};

export const getOriginalImageUrlFromSlices = (slices) =>{
  return slices.filter((info) => {
    return info.title === 'origin'
  })[0].url
}

// @see https://github.com/JosephusPaye/Keen-UI/blob/master/src/helpers/util.js

/**
 * Quick object check - this is primarily used to tell
 * Objects from primitive values when we know the value
 * is a JSON-compliant type.
 */
export function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}

/**
 * Check if two values are loosely equal - that is,
 * if they are plain objects, do they have the same shape?
 */
export function looseEqual(a, b) {
  // eslint-disable-next-line eqeqeq
  return a == b || (
    isObject(a) && isObject(b) ? JSON.stringify(a) === JSON.stringify(b) : false
  )
}

/**
 * Check if a val exists in arr using looseEqual comparison
 */
export function looseIndexOf(arr, val) {
  for (let i = 0; i < arr.length; i++) {
    if (looseEqual(arr[i], val)) {
      return i
    }
  }

  return -1
}

export function compare(a, b, attr) {
  let valueA = a[attr].toLowerCase()
  let valueB = b[attr].toLowerCase()

  if (valueA < valueB) {
    return -1
  }

  if (valueA > valueB) {
    return 1
  }

  return 0
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function getUniqueValues(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

export function maskPrice(value) {
  return value.toLocaleString('ru', { maximumFractionDigits: 0 })
}

export function unmaskPrice(value) {
  value = parseFloat(value.replace(/[^\d\.]/g, ""))

  return isNaN(value) ? '' : value
}

/**
 * Gets an image from the given url
 *
 * @param url
 * @param imageFormat
 *
 * @returns Promise
 */
export function getImageFromUrl(url, imageFormat = 'blob') {
  // TODO: It didn't work so I used the standard JS XMLHttpRequest
  // axios.get(this.look.previewUrl, {
  //   responseType: 'blob'
  // })
  return getImageFromServer(url).then(response => {
    let imageBlob = response

    switch(imageFormat) {
      case 'blob':
        return imageBlob
      case 'dataUrl':
        return getDataUrlFromBlob(imageBlob)
    }
  })
}

/**
 * Gets dataUrl (Base64 String) from image's blob
 *
 * @param blob
 *
 * @returns Promise
 */
export function getDataUrlFromBlob(blob) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader()

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = () => {
      reject(fileReader.error)
    }

    fileReader.readAsDataURL(blob)
  })
}

/**
 * Gets image blob from the server
 *
 * @param url
 *
 * @returns Promise
 */
function getImageFromServer(url) {
  return new Promise((resolve, reject) => {
    let httpRequest = new XMLHttpRequest()

    httpRequest.open('GET', url)
    httpRequest.responseType = 'blob'

    httpRequest.onload = () => {
      resolve(httpRequest.response)
    }

    httpRequest.onerror = () => {
      reject(httpRequest.error)
    }

    httpRequest.send()
  })
}