import countryTransformer from "./handbooks/countryTransformer"
import timeZoneTransformer from "./handbooks/timeZoneTransformer"
import figureTypeTransformer from "./handbooks/figureTypeTransformer"

export default handBooks => {
  return Object.assign({}, handBooks, {
    clothing_categories: transformClothingCategories(handBooks.clothing_categories),
    countries: handBooks.countries.map(countryTransformer),
    timezones: handBooks.timezones.map(timeZoneTransformer),
    figure_types: handBooks.figure_types.map(figureTypeTransformer)
  })
}

const transformClothingCategories = clothingCategories => {
  return clothingCategories.map(category => {
    const icon = category.icon ? category.icon.slices_info.find(info => info.title === 'origin') : null

    return {
      ...category,
      iconUrl: icon ? icon.url : null
    }
  })
}
