<template>
  <button class="base-button"
          :type="type"
          :class="classes"
          @click="$emit('click', $event)">
    <div class="content" v-show="!loading">
      <slot></slot>
    </div>

    <base-loader color="#fff" v-show="loading"></base-loader>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      required: false,
      type: String,
      default: 'button'
    },

    size: {
      type: String,
      default: 'normal'
    },

    disabled: {
      required: false,
      type: Boolean
    },

    loading: {
      required: false,
      type: Boolean
    },

    transparent: {
      required: false,
      type: Boolean,
      default: false,
    },

    active: {
      required: false,
      type: Boolean
    },

    square: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    classes() {
      return [
        { 'is-loading': this.loading },
        { 'is-disabled': this.disabled || this.loading },
        { 'is-transparent': this.transparent },
        { 'is-active' : this.active },
        { 'is-square': this.square },
        `size-${this.size}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $button-height;
  padding: 0 30px;

  font-size: $button-font-size;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  border: none;
  border-radius: 100px;

  background: $primary-color;

  transition: 0.3s all;

  &:hover, &.is-active {
    background-color: darken($primary-color, 10%);
  }

  &.is-disabled {
    background-color: lighten($primary-color, 10%);
    pointer-events: none;
  }

  &.is-square {
    border-radius: 6px;
  }

  &.is-transparent {
    color: $primary-color;
    background: none;
    box-shadow: none;
    border: 1px solid $primary-color;

    &:hover {
      color: #fff;
      background-color: $primary-color;
    }
  }

  &.size-small {
    font-size: $button-font-size-small;
    height: $button-height-small;
  }
}
</style>
