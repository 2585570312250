export default {
  props: {
    show: {
      required: true,
      type: Boolean
    },

    shake: {
      required: false,
      type: Boolean
    }
  },

  created() {
    const escapeHandler = e => {
      if (e.key === 'Escape' && this.show) {
        this.close()
      }
    }

    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  },

  methods: {
    onOpen() {
      document.body.classList.add('modal-opened')
    },

    close() {
      document.body.classList.remove('modal-opened')
      this.$emit('close')
    }
  },

  watch: {
    show() {
      this.$nextTick(() => {
        if (this.show) {
          this.onOpen()
        }
      })
    }
  },
}