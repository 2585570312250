export default {
  bind(el) {
    el.event = function() {
      el.style.height = 'auto'
      el.style.height = el.scrollHeight + el.offsetHeight - el.clientHeight + 'px'
    }

    el.addEventListener('input', el.event)
  },

  inserted(el) {
    el.event()
  },

  update(el) {
    el.event()
  },

  unbind(el) {
    el.removeEventListener('input', el.event)
  }
}