import Vue from 'vue'
import moment from 'moment'

Vue.filter('currencySymbol', (currency) => {
  switch (currency.iso_4217_code_numeric) {
    case 643: {
      return '₽'
    }
    case 840: {
      return '$'
    }
    case 978: {
      return '€'
    }
  }
})

Vue.filter('currency', (value) => {
  return value.toLocaleString('ru', { maximumFractionDigits: 0 })
})

Vue.filter('goodsNumber', (value) => {
  return value.toLocaleString('ru', { maximumFractionDigits: 0 })
})

Vue.filter('number', (value) => {
  return value.toLocaleString('ru', { maximumFractionDigits: 0 })
})

Vue.filter('date', value => {
  return value ? moment.unix(value).format('DD/MM') : null
})

Vue.filter('datetime', value => {
  return value ? moment.unix(value).format('DD.MM HH:mm') : null
})

Vue.filter('discount', value => {
  return '-' + Math.ceil(value * 100) + '%'
})