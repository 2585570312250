import userTypes from '@/constants/userTypes'
import errorCodes from '@/constants/errorCodes'
import keyCodes from '@/constants/keyCodes'
import currencySymbols from '@/constants/currencySymbols'
import artboardPictureTypes from '@/constants/artboardPictureTypes'

export default {
  userTypes,
  errorCodes,
  keyCodes,
  currencySymbols,
  artboardPictureTypes
}