export default {
  props: {
    look: {
      required: true,
      type: Object
    }
  },

  mounted() {
    this.initSocialSharing()
    this.initLinkIsCopiedPopup()
  },

  methods: {
    copyLink() {
      this.$copyText(this.look.publicLink).then(() => {
        this.$refs.link._tippy.show()
      })
    },

    initSocialSharing() {
      window.Sharer.init()
    },

    initLinkIsCopiedPopup() {
      tippy(this.$refs.link, {
        content: this.$refs.linkIsCopied,
        distance: 20,
        animation: 'shift-away',
        placement: 'bottom',
        arrow: true,
        trigger: 'manual',
        onShown(tooltip) {
          setTimeout(() => {
            tooltip.hide()
          }, 800)
        },
      })
    }
  }
}
