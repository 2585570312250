import clothingItemTransformer from '@/services/transformers/clothingItemTransformer'
import profileTransformer from '@/services/transformers/profileTransformer'
import router from '@/router/index'
import { getUniqueValues } from '@/services/utils'
import Store from '@/vuex/index'

export default look => {
  return Object.assign({}, {
    id: look.look_id,
    authorId: look.author_id,
    author: look.author ? profileTransformer(look.author) : null,
    client_id: look.client_id,
    title: look.title,
    description: look.description,
    status: look.g_generate_state,

    // TODO: Temporary there are two "is public", fix later
    is_public: look.is_public,
    isPublic: look.is_public,
    isDraft: look.is_draft,

    publicLink: location.origin + getPublicLink(look),

    // TODO: Proabably preview is not the best name, we should have both preview and original picture
    previewUrl: getPreviewUrl(look),

    totalAmountOfGoods: look.items.length,
    totalPrice: getTotalPrice(look),

    // Important to note the the items is not exactly clothing items.
    // The items is intermidate list of items which are used in
    // studio, containing geometry and clothing_item_id
    items: look.items,

    // While these are real clothing items
    clothingItems: getClothingItems(look),

    // Pictures which are not clothing items & uploaded by user manually
    userUploadedPictures: getUserUploadedPictures(look),

    lookContextId: look.look_context_id,
    lookContext: look.look_context_id ? getLookContext(look) : null,

    liked: look.is_liked,
    likesNumber: look.likes_count,
    meta_data: look.meta_data,
    createdAt: look.time_created,
    updatedAt: look.time_updated
  })
}

const getPublicLink = (look) => {
  return router.resolve({
    name: 'look',
    params: { userId: look.author_id, lookId: look.look_id },
  }).href
}

const getClothingItems = (look) => {
  return getUniqueValues(look.items, 'clothing_item_id')
    .map(item => clothingItemTransformer(item.clothing_item))
}

const getUserUploadedPictures = (look) => {
  return (look.meta_data && look.meta_data.userUploadedPictures) ? look.meta_data.userUploadedPictures : null
}

const getPreviewUrl = (look) => {
  return look.preview ? look.preview.slices_info.find((info) => {
    return info.title === 'origin'
  }).url : null
}

const getTotalPrice = (look) => {
  return getUniqueValues(look.items, 'clothing_item_id')
    .reduce((sum, item) => sum + item.clothing_item.price, 0)
}

const getLookContext = (look) => {
  let lookContexts = Store.state.handbooks.lookContexts

  return lookContexts.find(lookContext => {
    return lookContext.look_context_id === look.look_context_id
  })
}
