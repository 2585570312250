<template>
  <validation-provider ref="provider"
                       :rules="rules"
                       :vid="vid"
                       :name="name"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="base-multiselect">
      <label class="label base-field-label"
             v-if="label">
        {{ label }}
      </label>

      <multiselect :value="value"
                   :options="options"
                   :multiple="multiple"
                   :label="optionLabel"
                   :track-by="trackBy"
                   :placeholder="placeholder"
                   :searchable="searchable"
                   :open-direction="openDirection"
                   :show-no-results="showNoResults"
                   :show-labels="showLabels"
                   :loading="loading"
                   :close-on-select="closeOnSelect"
                   :internal-search="internalSearch"
                   :clear-on-select="clearOnSelect"
                   :preserve-search="preserveSearch"
                   :options-limit="optionsLimit"
                   :limit="limit"
                   :max-height="maxHeight"
                   :name="name"
                   @search-change="$emit('search-change', $event)"
                   @input="$emit('input', $event)">
        <template slot="option"
                  slot-scope="props"
                  v-if="$scopedSlots.option">
          <slot name="option"
                :option="props.option"></slot>
        </template>

        <template slot="singleLabel"
                  slot-scope="props"
                  v-if="$scopedSlots.singleLabel">
          <slot name="singleLabel"
                :option="props.option"></slot>
        </template>

        <template slot="noResult"
                  v-if="showNoResults">
          <slot name="noResult"></slot>
        </template>
      </multiselect>

      <div class="error"
           v-show="showValidationErrors && errors.length > 0">
        {{ errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>


<script>
  import Multiselect from 'vue-multiselect'
  import validatable from '@/services/mixins/validatable.js'

  export default {
    mixins: [
      validatable
    ],

    props: {
      value: {
        required: true
      },

      options: {
        required: true,
        type: Array
      },

      multiple: {
        required: false,
        type: Boolean,
        default: false,
      },

      label: {
        required: false,
        type: String
      },

      optionLabel: {
        required: true,
        type: String
      },

      trackBy: {
        required: true,
        type: String
      },

      placeholder: {
        required: true,
        type: String
      },

      searchable: {
        required: false,
        type: Boolean,
        default: true
      },

      openDirection: {
        required: false,
        type: String,
        default: ''
      },

      showNoResults: {
        required: false,
        type: Boolean,
        default: false
      },

      showLabels: {
        required: false,
        type: Boolean,
        default: false
      },

      loading: {
        required: false,
        type: Boolean,
        default: false
      },

      internalSearch: {
        required: false,
        type: Boolean,
        default: true,
      },

      clearOnSelect: {
        required: false,
        type: Boolean,
        default: true
      },

      preserveSearch: {
        required: false,
        type: Boolean,
        default: false
      },

      optionsLimit: {
        required: false,
        type: Number,
        default: 1000
      },

      limit: {
        required: false,
        type: Number,
        default: 99999
      },

      maxHeight: {
        required: false,
        type: Number,
        default: 300
      },

      closeOnSelect: {
        required: false,
        type: Boolean,
        deafult: true
      },

      showValidationError: {
        required: false,
        type: Boolean,
        default: false
      },

      validationRules: {
        required: false
      },

      name: {
        required: false,
        type: String,
        default: 'name'
      }
    },

    components: {
      Multiselect,
    },

    methods: {
      validate() {
        return this.$validator.validateAll()
      },
    },

    computed: {
      showValidationErrors() {
        return this.showValidationError && this.errors.has(this.name)
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>


  .base-multiselect {
    width: 100%;

    .label {
      margin-bottom: 16px;
    }
  }

  /deep/ .multiselect {
    .multiselect__tag {
      background: $primary-color;

      .multiselect__tag-icon {
        &:hover {
          background: darken($primary-color, 10%);
        }

        &::after {
          color: #424242;
        }
      }
    }

    .multiselect__option {
      color: #1c1c1c;
    }

    .multiselect__option--highlight {
      background: #F3F3F3;
    }

    .multiselect__option--selected {
      font-weight: normal;
      color: #fff;
      background: lighten($primary-color, 10%);
    }
  }

  .error {
    margin-top: 5px;
    font-size: 14px;
    color: #FF7474;
  }
</style>
