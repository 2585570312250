export const removeClothingItem = ({ commit }, clothingItem) => {
  commit('removeClothingItem', clothingItem)
}

export const addLook = ({ state, commit, getters }, look) => {
  look.clothingItems.forEach(lookClothingItem => {
    if (getters.isItemInCart(lookClothingItem)) {
      return
    }

    commit('addClothingItem', lookClothingItem)
  })
}

export const addClothingItem = ({ commit }, clothingItem) => {
  commit('addClothingItem', clothingItem)
}

export const updateClothingItem = ({ commit }, { id, data }) => {
  commit('updateClothingItem', { id, data })
}