export default {
  props: {
    vid: {
      required: false,
      type: String
    },

    rules: {
      required: false,
      type: [Object, String, null],
      default: ''
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: true
    }
  }
}
