export default {
  props: {
    icon: {
      type: String,
      required: true
    },

    width: {
      type: String,
      required: false,
      default: '20px'
    },

    height: {
      type: String,
      required: false,
      default: '20px'
    },

    color: {
      type: String,
      required: false
    },

    viewBox: {
      type: String,
      required: false
    }
  },

  computed: {
    xLinkHref() {
      return '#' + this.icon
    },

    svgStyle() {
      return {
        width: this.width,
        height: this.height,
        fill: this.color ? this.color : false
      }
    }
  }
}