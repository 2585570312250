<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 100 100"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m30.199 30.199c1.9531-1.9531 5.1172-1.9531 7.0703 0l12.727 12.727 12.727-12.727c1.9531-1.9531 5.1172-1.9531 7.0703 0s1.9531 5.1172 0 7.0703l-12.727 12.727 12.727 12.727c1.9531 1.9531 1.9531 5.1172 0 7.0703s-5.1172 1.9531-7.0703 0l-12.723-12.723-12.727 12.727c-1.9531 1.9531-5.1172 1.9531-7.0703 0s-1.9531-5.1172 0-7.0703l12.727-12.727-12.727-12.727c-1.9531-1.9531-1.9531-5.1172 0-7.0703z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from '@/services/mixins/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
