<template>
  <div class="base-menu-slide">
    <transition :enter-active-class="enterActiveClass"
                :leave-active-class="leaveActiveClass">
      <div class="menu"
           :class="classes"
           v-if="isOpen"
           v-click-outside="close">
        <base-icon icon="cross"
                   class="close"
                   @click="close"></base-icon>
        <slot></slot>
      </div>
    </transition>

    <transition enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut">
      <div v-if="isOpen"
           class="overlay"></div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      show: {
        required: false,
        type: Boolean,
        default: false
      },

      slideFrom: {
        required: false,
        type: String,
        default: 'left'
      }
    },

    data() {
      return {
        isOpen: this.show
      }
    },

    methods: {
      open() {
        document.body.classList.add('mobile-menu-open')
        this.isOpen = true
      },

      close() {
        document.body.classList.remove('mobile-menu-open')
        this.isOpen = false
        this.$emit('close')
      },
    },

    computed: {
      enterActiveClass() {
        let result = 'animated '

        if (this.slideFrom === 'left') {
          return result + 'slideInLeft'
        }

        if (this.slideFrom === 'right') {
          return result + 'slideInRight'
        }
      },

      leaveActiveClass() {
        let result = 'animated '

        if (this.slideFrom === 'left') {
          return result + 'slideOutLeft'
        }

        if (this.slideFrom === 'right') {
          return result + 'slideOutRight'
        }
      },

      classes() {
        return [
          { 'stick-to-left':  this.slideFrom === 'left' },
          { 'stick-to-right': this.slideFrom === 'right' }
        ]
      }
    },

    watch: {
      show() {
        this.show ? this.open() : this.close()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .base-menu-slide {
    .menu {
      position: fixed;
      z-index: map-get($zIndexes, base-menu-slide);
      top: 0;
      bottom: 0;
      width: 300px;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;

      &.stick-to-left {
        left: 0;
      }

      &.stick-to-right {
        right: 0;
      }

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .fadeIn {
      animation: fadeIn 0.3s;
    }

    .fadeOut {
      animation: fadeOut 0.3s;
    }

    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: map-get($zIndexes, base-menu-slide-overlay);
      background-color: rgba($primary-color, .9);
    }
  }
</style>
