import lookTransformer from './lookTransformer'
import Store from '@/vuex/index'
import userTypes from '@/constants/userTypes.js'

export default profile => {
  return Object.assign({}, profile, {
    id:              profile.user_id,
    fullName:        profile.full_name,
    nickName:        profile.nick_name,
    avatarUrl:       getAvatarUrl(profile),
    lookNumber:      profile.look_count       || 0,
    followingNumber: profile.followings_count || 0,
    followersNumber: profile.followers_count  || 0,
    reviewsNumber:   profile.review_count     || 0,
    likesNumber:     profile.likes_count      || 0,
    averageRating:   profile.rating_avg       || 0,
    isFollowing:     profile.is_following,
    country:         profile.country_id ? getCountry(profile) : null,
    looks:           (profile.looks && profile.looks.length > 0) ? profile.looks.map(look => lookTransformer(look)) : [],
    isClient:        profile.user_type === userTypes.client,
    isAgent:         profile.user_type === userTypes.agent,
    isStylist:       profile.user_type === userTypes.stylist,
    isMainStylist:   profile.is_admin,
    isAdmin:         profile.user_type === userTypes.admin,
  })
}

const getAvatarUrl = (user, pictureSize) => {
  return user.avatar ? user.avatar.slices_info.find(info => {
    return info.title === 'origin'
  }).url : null
}

const getCountry = (profile) => {
  return Store.getters['handbooks/getCountryById'](profile.country_id)
}
