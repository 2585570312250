import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from '@/services/locale'

Vue.use(VueI18n)

export default () => {
  return new VueI18n({
    locale: locale(),
    fallbackLocale: 'ru_RU',
    messages: {
      'ru_RU': require('@/lang/ru_RU.json'),
      'en_US': require('@/lang/en_US.json')
    }
  })
}

// import messages from '@/lang'
// import axios from 'axios'

//
// const loadedLanguages = ['en']
//
// function setI18nLanguage(lang) {
//   i18n.locale = lang
//   axios.defaults.headers.common['Accept-Language'] = lang
//   document.querySelector('html').setAttribute('lang', lang)
// }
//
// export function loadLanguageAsync (lang) {
//   if (i18n.locale === lang) { return; }
//
//   if (i18n.locale !== lang) {
//     if (!loadedLanguages.includes(lang)) {
//       return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`).then(msgs => {
//         i18n.setLocaleMessage(lang, msgs.default)
//         loadedLanguages.push(lang)
//         return setI18nLanguage(lang)
//       })
//     }
//     return Promise.resolve(setI18nLanguage(lang))
//   }
//
//   return Promise.resolve(lang)
// }