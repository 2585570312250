<template>
  <div class="alert" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        required: false,
        type: String,
        default: 'info'
      },
      centerText: {
        required: false,
        type: Boolean,
        default: false
      }
    },

    computed: {
      classes() {
        return [
          { 'alert-info':    this.type === 'info'    },
          { 'alert-error':   this.type === 'error'   },
          { 'alert-success': this.type === 'success' },
          { 'text-center':   this.centerText }
        ]
      }
    }
  }
</script>

<style lang="scss">
  .alert {
    position: relative;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .alert-error {
    border-color: #f5c6cb;
    background-color: #f8d7da;
    color: #721c24;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
</style>