import i18n from '@/services/i18n'

export default {
  props: {
    show: {
      required: true,
      type: Boolean,
      default: false
    },

    title: {
      required: false,
      type: String,
      default: i18n().t('base.baseModalConfirm.default.title')
    },

    confirmButtonText: {
      required: false,
      type: String,
      default: i18n().t('base.baseModalConfirm.default.buttons.confirm')
    },

    cancelButtonText: {
      required: false,
      type: String,
      default: i18n().t('base.baseModalConfirm.default.buttons.cancel')
    },

    inProgress: {
      required: false,
      type: Boolean,
      default: false
    },

    serverError: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}