import constants from '@/constants/constants'

export default {
  data() {
    return {
      get constants$() {
        return getReadOnlyConstants()
      }
    }
  }
}

/**
 * Generates read-only constants (closed for modification, extension and deletion)
 *
 * @returns {object}
 */
function getReadOnlyConstants() {
  let readOnlyConstants = {}

  Object.keys(constants).forEach(constantsNamespace => {
    readOnlyConstants[constantsNamespace] = _.cloneDeep(constants[constantsNamespace])
  })

  Object.freeze(readOnlyConstants)

  return readOnlyConstants
}