export default {
  props: {
    icon: {
      type: String,
      required: true
    },

    iconWidth: {
      type: String,
      required: false,
      default: '20px'
    },

    iconHeight: {
      type: String,
      required: false,
      default: '20px'
    },

    iconColor: {
      type: String,
      required: false
    },

    buttonClass: {
      type: String,
      required: false
    }
  }
}