export default () => {
  return {
    look: {
      existing: false,
      id: null,
      client_id: null,
      title: '',
      description: '',
      isPublic: false,
      previewPicture: null,
      items: [],
    },

    filters: {
      clothingSizes:  [],
      partners:       []
    }
  }
}
