<template>
  <modal :name="name"
         :width="width"
         :height="height"
         :scrollable="true"
         @before-open="onBeforeOpen"
         @opened="$emit('opened')">
    <div class="modal" :style="style">
      <header class="modal-header" v-if="hasHeader">
        <slot name="header">
          <button type="button"
                  class="btn-close"
                  @click="close"
                  v-if="showCloseButton">x</button>
        </slot>
      </header>
      <section class="modal-body">
        <slot></slot>
      </section>
      <footer class="modal-footer" v-if="hasFooter">
        <slot name="footer"></slot>
      </footer>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    name: {
      required: 'false',
      type: String
    },

    show: {
      required: false,
      type: Boolean,
      default: false
    },

    showCloseButton: {
      required: false,
      type: Boolean,
      default: true
    },

    width: {
      required: false
    },

    height: {
      required: false,
      default: 'auto'
    }
  },

  created() {
    const escapeHandler = e => {
      if (e.key === 'Escape' && this.show) {
        this.close()
      }
    }

    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })

    if (this.show) {
      document.body.classList.add('modal-opened')
    }
  },

  methods: {
    onBeforeOpen (event) {
      console.log('onBeforeOpen', event)
      this.$emit('beforeOpen', event)
    },

    onOpen() {
      document.body.classList.add('modal-opened')
    },

    onClose() {
      document.body.classList.remove('modal-opened')
    },

    close() {
      this.onClose()
      this.$emit('close')
    }
  },

  computed: {
    hasHeader() {
      return Boolean(this.$slots.header)
    },

    hasFooter() {
      return Boolean(this.$slots.footer)
    },

    style() {
      return {
        width: this.width ? this.width : 'auto',
        height: this.height ? this.height : 'auto',
      }
    }
  },

  watch: {
    show() {
      this.$nextTick(() => {
        if (this.show) {
          this.onOpen()
        } else {
          this.onClose()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index:  map-get($zIndexes, modal);
  background-color: rgba($primary-color,.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  //width: 100vw;
  //height: 100vh;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 35px 30px 30px;
}

.modal-header,
.modal-footer {
  display: flex;
}

.modal-header {
  justify-content: space-between;
  font-size: 26px;
  line-height: 36px;
  font-weight: bold;
  color: #6981d3;
  margin-bottom: 14px;
}

.modal-footer {
  justify-content: flex-end;
  margin-top: 30px;
}

.modal-body {
  position: relative;
  flex: 1;
  font-size: 14px;
  line-height: 23px;
  color: #797979;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}
</style>
