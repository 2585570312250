import VueSlider from 'vue-slider-component'

export default {
  props: {
    value: {
      required: true,
      type: [Number, Array]
    },

    format: {
      required: false,
      type: Function,
      default: (value) => {
        return value
      }
    },

    min: {
      required: false,
      type: Number,
      default: 100
    },

    max: {
      required: false,
      type: Number,
      default: 500000
    },

    lazy: {
      required: false,
      type: Boolean,
      default: false
    },

    tooltip: {
      required: false,
      type: Boolean,
      default: false
    },

    // TODO: implement this
    showRangeValues: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  components: {
    VueSlider
  },

  data() {
    return {
      currentSliderValue: this.value,

      sliderOptions: {
        width: '100%',
        height: 1,
        dotWidth: 48,
        dotHeight: 39,
        min: this.min,
        max: this.max,
        lazy: this.lazy,
        tooltip: this.tooltip,
        processStyle: {
          backgroundColor: '#2ADBD3'
        }
      }
    }
  },

  methods: {
    refresh() {
      this.$nextTick(() => this.$refs.slider.refresh())
    },

    getTooltipValue(tooltip) {
      return this.tooltipValueFilter(tooltip.value)
    },

    setValue(value) {
      this.$refs.slider.setValue(value)
    }
  },

  computed: {
    sliderValue: {
      get() {
        return this.value
      },

      set(value) {
        this.currentSliderValue = value
      }
    }
  },

  watch: {
    currentSliderValue(value) {
      this.$emit('input', value)
    }
  }
}