export default country => {
  return {
    id: country.country_id,
    title: country.title,
    images: getImages(country),
    iconUrl: getIconUrl(country)
  }
}

const getImages = (country) => {
  let images = []

  country.icon.slices_info.forEach(item => {
    images[item.title] = {
      width:  item.width,
      height: item.height,
      url:    item.url
    }
  })

  return images
}

const getIconUrl = country => {
  return country.icon.slices_info.find(icon => icon.title === 'small').url
}