export default figureType => {
  return {
    ...figureType,
    id: figureType.figure_type_id,
    images: getImages(figureType)
  }
}

const getImages = figureType => {
  let images = {}

  figureType.icon.slices_info.forEach(item => {
    images[item.title] = {
      width: item.width,
      height: item.height,
      url: item.url
    }
  })

  return images
}