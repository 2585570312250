import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { looseEqual, looseIndexOf } from '@/services/utils'

export default {
  model: {
    prop: 'selectedItems',
    event: 'update'
  },

  props: {
    options: {
      required: true,
      type: Array
    },

    selectedItems: {
      required: true,
      type: Array
    },

    title: {
      required: true,
      type: String
    },

    label: {
      required: false,
      type: String,
      default: 'label'
    },

    multiple: {
      required: false,
      default: false
    },

    displaySearch: {
      required: false,
      default: false
    }
  },

  components: {
    VuePerfectScrollbar
  },

  data() {
    return {
      search: '',
      internalSelectedItems: _.cloneDeep(this.selectedItems)
    }
  },

  methods: {
    toggle(option) {
      if (!this.multiple) {
        this.internalSelectedItems = []
      }

      let index = looseIndexOf(this.internalSelectedItems, option)

      if (index === -1) {
        this.internalSelectedItems.push(option)
      } else {
        this.internalSelectedItems.splice(index, 1)
      }

      this.$emit('update', _.cloneDeep(this.internalSelectedItems))
    },

    optionSelected(option) {
      if (this.multiple) {
        return looseIndexOf(this.internalSelectedItems, option) > -1
      }

      return looseEqual(this.internalSelectedItems, option)
    },

    close() {
      this.$emit('close')
    },

    getOptionLabel(option) {
      return option[this.label]
    }
  },

  computed: {
    filteredOptions() {
      return this.options.filter((option) => {
        return option[this.label].toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      })
    }
  },

  watch: {
    selectedItems: function(items) {
      this.internalSelectedItems = _.cloneDeep(items)
    }
  }
}