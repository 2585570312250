import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

let defaultState = {
  authenticated: false,
  currentUser: null,
  accessToken: null,
  expireToken: null,
  invitedUser: null
}

let vuexState = localStorage.getItem('vuexState') ?
    JSON.parse(localStorage.getItem('vuexState')) : null

let state = vuexState && vuexState.auth || defaultState

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
