import { getOriginalImageUrlFromSlices } from '@/services/utils'
import profileService from '@/services/queries/profileQueries.js'

export const updateCurrentUser = ({ dispatch }) => {
  return profileService.find('me').then(async response => {
    let user = response

    if (!user.gender) {
      user = await profileService.update('me', {'gender': 'female'})
    }

    if (!user.meta_data?.test) {
      user = await profileService.update('me', {
        meta_data: {
          ...user.meta_data,
          'test': true
        }
      })
    }

    dispatch('setCurrentUser', user)
  })
}

export const setCurrentUser = ({ commit }, user) => {
  user.avatarUrl = user.avatar ? getOriginalImageUrlFromSlices(user.avatar.slices_info) : null

  commit('setCurrentUser', user)
}

export const setAuthorizationTokens = ({ commit }, { accessToken, expireToken }) => {
  commit('setAccessToken', accessToken)
  commit('setExpireToken', expireToken)
}

export const clearAuthInfo = ({ commit }) => {
  commit('clearAuthInfo')
}

export const setLocale = ({ commit }, locale) => {
  commit('setLocale', locale)
}

export const setInvitedUser =  ({ commit }, invitedUser) => {
  commit('setInvitedUser', invitedUser)
}
