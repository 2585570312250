import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

const state = {
  authModalSettings: {
    open: false,
    content: 'login',
    user: null
  },

  wearGenerationModalSettings: {
    open: false,
    clothingId: null,
    user: null
  },

  avatarUploadState: {
    uploaded: false,
    avatar: null,
    avatarData: null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
