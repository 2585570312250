export const setFilters = ({ commit }, filters) => {
  commit('setFilters', filters)
}

export const setLook = ({ commit }, look) => {
  commit('setLook', look)
}

export const clearLook = ({ commit }) => {
  commit('clearLook')
}

export const resetFilters = ({ commit }) => {
  commit('resetFilters')
}

export const addClothingItem = ({ commit }, clothingItem) => {
  commit('addClothingItem', clothingItem)
}