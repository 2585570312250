import initialState from './initialState'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

let vuexState = localStorage.getItem('vuexState') ?
    JSON.parse(localStorage.getItem('vuexState')) : null

let state = vuexState && vuexState.studio || initialState()

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}