export default {
  props: {
    color: {
      required: false,
      type: String
    }
  },

  computed: {
    style() {
      return {
        backgroundColor: this.color
      }
    }
  }
}