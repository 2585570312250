export default {
  props: {
    user: {
      required: true
    },

    size: {
      require: false,
      default: 'normal'
    },

    width: {
      required: false
    },

    height: {
      required: false
    }
  },

  computed: {
    userRegistered() {
      return !this.user.transient
    },

    userHasAvatar() {
      return !!this.user.avatarUrl
    },

    userPicture() {
      return {
        background: this.user.avatarUrl ?
            `url(${this.user.avatarUrl}) center center / contain no-repeat` : 'none'
      }
    },

    classes() {
      return [
        `size-${this.size}`
      ]
    },

    styles() {
      return {
        width: this.width ? this.width : null,
        height: this.height ? this.height : null
      }
    },

    noAvatarSize() {
      switch (this.size) {
        case 'small': {
          return {
            width: '20px',
            height: '20px'
          }
        }
        case 'normal': {
          return {
            width: '30px',
            height: '30px'
          }
        }
        case 'big': {
          return {
            width: '60px',
            height: '60px'
          }
        }
        case 'stretch': {
          return {
            width: '50%',
            height: '50%'
          }
        }
      }
    }
  }
}