export default timeZone => {
  return {
    ...timeZone,
    id: timeZone.timezone_id,
    utcOffset: getUtcOffset(timeZone),
    title: timeZone.title,
    cities: timeZone.cities,
  }
}

const getUtcOffset = timeZone => {
  let utcOffsetAbsoluteValue = Math.abs(timeZone.utc_offset)
  let hours = Math.floor(utcOffsetAbsoluteValue / 60)
  let minutes = utcOffsetAbsoluteValue - hours * 60
  let hourString = hours < 10 ? `0${hours}` : `${hours}`
  let minuteString = minutes < 10 ? `0${minutes}` : `${minutes}`
  let isOffsetPositive = utcOffsetAbsoluteValue === timeZone.utc_offset

  return `UTC${isOffsetPositive ? '+' : '-'}${hourString}:${minuteString}`
}