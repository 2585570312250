export const openAuthModal = ({ commit }, { content = 'login', user = null } = {}) => {
  commit('setAuthModalSettings', { open: true, content, user })
}

export const closeAuthModal = ({ commit }) => {
  commit('setAuthModalSettings', { open: false })
}

export const setAvatarUploadState = ({ commit }, { avatar, avatarData }) => {
  commit('setAvatarUploadState', { avatar, avatarData })
}

export const clearAvatarUploadState = ({ commit }) => {
  commit('clearAvatarUploadState')
}
