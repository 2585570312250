export default {
  'b': 66,
  'd': 68,
  'm': 77,
  'n': 78,
  'p': 80,
  'z': 90,
  '[': 219,
  ']': 221,
  'backspace': 8,
  'delete': 46,
  'cmdLeft': 91,
  'cmdRight': 93,
  'arrowLeft': 37,
  'arrowRight': 39
}