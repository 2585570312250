<template>
  <base-checkbox v-model="internalValue" class="base-checkbox-agreement">
    {{ $t('base.baseCheckboxAgreement.label.part1') }}
    <a href="http://dnato.style/terms" target="_blank">
      {{ $t('base.baseCheckboxAgreement.label.part2') }}
    </a>
  </base-checkbox>
</template>

<script>
  export default {
    props: {
      value: {
        required: true
      }
    },

    data() {
      return {
        internalValue: this.value
      }
    },

    watch: {
      value: function(value) {
        this.internalValue = value
      },

      internalValue: function(value) {
        this.$emit('input', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .base-checkbox-agreement a {
    font-size: 14px;
    color: $primary-color;
    text-decoration: underline;
  }
</style>
