<template>
  <div class="base-icon-like"
       :class="componentClasses"
       @mouseover="isHovered = true"
       @mouseleave="isHovered = false"
       @click="onClick">
    <base-icon class="icon-like"
               :class="likeIconClasses"
               icon="like"/>
    <span class="likes-number"
          :class="likesNumberClasses"
          v-if="showLikesNumber">
      {{ likesNumber }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      clickable: {
        required: false,
        type: Boolean,
        default: true
      },

      active: {
        required: false,
        type: Boolean,
        default: false
      },

      showLikesNumber: {
        required: false,
        type: Boolean,
        default: false
      },

      likesNumber: {
        required: false,
        type: Number
      }
    },

    data() {
      return {
        isHovered: false
      }
    },

    methods: {
      onClick(event) {
        if (!this.clickable) {
          return
        }

        this.$emit('click', event)
      }
    },

    computed: {
      componentClasses() {
        return {
          'base-icon-like-clickable': this.clickable
        }
      },

      likeIconClasses() {
        return {
          'icon-like-clickable': this.clickable,
          'icon-like-active': this.clickable && this.active,
          'icon-like-hover': this.clickable && this.isHovered
        }
      },

      likesNumberClasses() {
        return {
          'likes-number-hover': this.clickable && this.isHovered
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.base-icon-like {
  display: flex;
  align-items: center;

  &.base-icon-like-clickable {
    cursor: pointer;
  }

  .icon-like {
    fill: rgba(#fff, 0);
    stroke: #797979;

    transition: all 0.3s;

    &.icon-like-clickable {
      stroke: $primary-color;
    }

    &.icon-like-hover {
      fill: #f95731;
      stroke: #f95731;
    }

    &.icon-like-active {
      fill: #f95731;
      stroke: #f95731;
    }
  }

  .likes-number {
    margin-left: 4px;
    color: #797979;

    &.likes-number-hover {
      color: $primary-color;
    }
  }
}
</style>
