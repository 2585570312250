import artboardPictureTypes from '@/constants/artboardPictureTypes.js'
import Store from '@/vuex/index'
import currencySymbols from '@/constants/currencySymbols'

export default clothingItem => {
  return Object.assign({}, clothingItem, {
    id:               clothingItem.clothing_item_id,
    clothingItemType: artboardPictureTypes.existingClothing,
    currencySymbol:   getCurrencySymbol(clothingItem),
    partner:          Store.getters['handbooks/getPartnerById'](clothingItem.partner_id),
    currency:         Store.getters['handbooks/getCurrencyById'](clothingItem.local_price_currency_id),
    partnerId:        clothingItem.partner_id,
    currencyId:       clothingItem.local_price_currency_id,
    brandId:          clothingItem.brand_id,
    partnerUrl:       clothingItem.partner_side_info.url,
    mainImage:        getMainImage(clothingItem),
    liked:            clothingItem.is_liked,
    oldPrice:         clothingItem.local_old_price ? clothingItem.local_old_price : null,
    discount:         getDiscount(clothingItem)
  })
}

const getCurrencySymbol = (clothingItem) => {
  let currency = Store.getters['handbooks/getCurrencyById'](clothingItem.local_price_currency_id)

  return currencySymbols[currency.iso_4217_code_numeric]
}

const getMainImage = (clothingItem) => {
  let image = clothingItem.images.find(image => image.is_main)

  if (!image) {
    image = clothingItem.images[0]
  }

  if (!image) {
    return {
      origin: {
        url: 'https://via.placeholder.com/200x200'
      },
      middle: {
        url: 'https://via.placeholder.com/200x200'
      }
    }
  } else {
    return {
      origin: image.slices_info.find(sliceInfo => sliceInfo.title === 'origin'),
      middle: image.slices_info.find(sliceInfo => sliceInfo.title === 'middle')
    }
  }
}

const getDiscount = (clothingItem) => {
  if (!clothingItem.local_old_price || clothingItem.local_old_price < clothingItem.price) {
    return null
  }

  let discount = (clothingItem.local_old_price - clothingItem.price) / clothingItem.local_old_price

  // @see https://stackoverflow.com/a/12830454/1064428
  return +discount.toFixed(2)
}
