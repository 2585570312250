import tippy from 'tippy.js'

export default {
  inserted(el, binding) {
    el.tabIndex = -1

    if (binding.value) {
      tippy(el, {
        content: binding.value,
        arrow: true,
      })
    }
  }
}
