export const authenticated = (state) => {
  return state.authenticated
}

export const currentUser = (state) => {
  return state.currentUser
}

export const accessToken = (state) => {
  return state.accessToken
}

export const expireToken = (state) => {
  return state.expireToken
}

export const locale = (state) => {
  return state.locale
}

export const invitedUser = (state) => {
  return state.invitedUser
}
