export default {
  props: {
    name: {
      required: false,
      type: String
    }
  },

  mounted() {
   this.$refs.input.addEventListener('change', (event) => {
     this.$emit('upload', event.currentTarget.files)
   })
  }
}