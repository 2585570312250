import Vue from 'vue'
import router from '@/router/index.js'
import VuePortal from 'portal-vue'
import VueClipboard from 'vue-clipboard2'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import VueMq from 'vue-mq'
import VueGtm from 'vue-gtm'
import VueSweetalert2 from 'vue-sweetalert2';
import infiniteScroll from 'vue-infinite-scroll'
import 'sharer.js/sharer.min.js'

Vue.use(VueMeta)
Vue.use(VModal)
Vue.use(VuePortal)
Vue.use(VueClipboard)
Vue.use(infiniteScroll)
Vue.use(VueSweetalert2)

Vue.use(VueGtm, {
  enabled: true,
  debug: process.env.NODE_ENV !== 'production',
  vueRouter: router,
})

Vue.use(VueMq, {
  breakpoints: {
    mobile: 481,
    tablet: 1024,
    desktop: Infinity
  }
})
