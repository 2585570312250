import initialState from './initialState'

export const setFilters = (state, filters) => {
  state.filters = filters
}

export const setLook = (state, look) => {
  state.look = look
}

export const clearLook = (state) => {
  state.look = initialState().look
}

export const reset = (state) => {
  let s = initialState()

  Object.keys(initialState).forEach(key => {
    state[key] = initialState[key]
  })
}

export const resetFilters = (state) => {
  let s = initialState()

  Object.keys(s.filters).forEach(key => {
    state.filters[key] = s.filters[key]
  })
}

export const addClothingItem = (state, clothingItem) => {
  state.look.items.push(clothingItem)
}