import Vue from 'vue'
import NProgress from 'nprogress'
import { authenticated, getAccessToken, logout, refreshTokens } from '@/services/auth'
import getLocale from '@/services/locale'
import errorCodes from '@/constants/errorCodes.js'

const init = () => {
  axios.defaults.baseURL = Vue.config.baseUrl
  axios.defaults.responseType = 'json'
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.headers.patch['Content-Type'] = 'application/json'

  setRequestSettings()
  setResponseSettings()

  if (authenticated()) {
    axios.defaults.headers['X-Lamb-Auth-Token'] = getAccessToken()
  } else {
    axios.defaults.headers['X-Lamb-Device-Locale'] = getLocale()
  }
}

const setRequestSettings = () => {
  axios.interceptors.request.use(config => {
    NProgress.start()

    return config
  }, (error) => {
    NProgress.done()

    return Promise.reject(error)
  })
}

const setResponseSettings = () => {
  axios.interceptors.response.use(response => {
    NProgress.done()

    return response
  }, (error) => {
    NProgress.done()

    if (!error.response || error.response.status !== 401 || !authenticated()) {
      return Promise.reject(error)
    }

    if (error.response.data.error_code === errorCodes.authExpired) {
      refreshTokens()

      return
    }

    logout()
    window.location.href = '/'
  })
}

export default init