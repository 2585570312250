import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/vuex/index'
import { loadSvg, loadComponents, loadIcons }  from './bootstrap'
import { localize } from 'vee-validate/dist/vee-validate.full'
import initAxios from '@/services/axios'
import initSocialNetworks from '@/services/socialNetworks'
import '@/services/initialization/plugins.js'
import '@/services/directives/directives.js'
import '@/services/filters.js'
import '@/services/validation.js'
import constantsMixin from '@/services/mixins/constantsMixin.js'
import constants from '@/constants/keyCodes.js'
import './directives.js'
import tippy from 'tippy.js'

window.tippy = tippy
tippy.setDefaultProps({
  allowHTML: true,
  appendTo: document.body
})

Vue.config.productionTip = false
Vue.config.baseUrl = process.env.VUE_APP_BASE_URL

Vue.config.keyCodes.arrowLeft = constants.arrowLeft
Vue.config.keyCodes.arrowRight = constants.arrowRight

setLocaleFromRoute()
loadSvg()
loadComponents()
loadIcons()
initSocialNetworks()
initAxios()

initApp()

Vue.use({
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapState('auth', {
          $currentUser: 'currentUser',
        })
      }
    })
  }
})

function initApp() {
  let getLocale = (require('@/services/locale').default)()
  let i18n = require('@/services/i18n').default()

  localize(getLocale, require(`@/lang/validation/${getLocale}.js`).default)
  Vue.mixin(constantsMixin)

  new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

// TODO: Refactor localization
function setLocaleFromRoute() {
  let routeParams = (new URL(window.location)).searchParams
  let routeLocale = routeParams.get('locale')

  if (!routeLocale) {
    return
  }

  if (routeLocale.toLowerCase().includes('ru')) {
    localStorage.setItem('locale', 'ru_RU')
  } else if (routeLocale.toLowerCase().includes('en')) {
    localStorage.setItem('locale', 'en_US')
  }
}
