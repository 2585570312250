<template>
  <validation-provider ref="provider"
                       :rules="rules"
                       :vid="vid"
                       :name="name"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="base-text-box" :class="classes">
      <label class="base-field-label"
             v-if="label"
             @click="focus">{{ label }}</label>

      <div class="input-wrapper"
           v-if="!multiLine">
        <input :id="id"
               ref="input"
               class="input"
               :name="name"
               :type="type"
               :value="value"
               :disabled="disabled"
               :autocomplete="autocomplete"
               :placeholder="placeholder"
               @input="onInput"
               @change="onChange"
               :inputmode="inputmode"
               :pattern="pattern">

        <button class="password-button"
                type="button"
                @click="$emit('passwordVisibilityButtonClick')"
                v-if="showPasswordVisibilityButton">
          {{ showPassword ? $t('base.baseTextBox.hidePassword') : $t('base.baseTextBox.showPassword') }}
        </button>

        <a class="password-link"
           href="#"
           @click.prevent="$emit('askPasswordRecovery')"
           v-if="showPasswordRecoveryLink">
          {{ $t('base.baseTextBox.forgotPassword') }}
        </a>
      </div>

      <textarea :id="id"
                ref="input"
                class="textarea"
                :name="name"
                :type="type"
                :value="value"
                :rows="rows"
                :disabled="disabled"
                :placeholder="placeholder"
                @input="onInput"
                @change="onChange"
                v-if="multiLine"></textarea>

      <div class="bottom">
        <div class="error"
             v-show="showErrors && errors.length > 0">
          {{ errors[0] }}
        </div>

        <div class="chars-counter" v-if="showCharsCounter">
          {{ value.length }} / {{ maxChars }}
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import validatable from '@/services/mixins/validatable.js'

export default {
  mixins: [
    validatable
  ],

  props: {
    id: {
      required: false,
      type: String
    },

    value: {
      required: true
    },

    type: {
      required: false,
      type: String,
      default: 'text'
    },

    inputmode: {
      required: false,
      type: String,
      default: 'text'
    },

    pattern: {
      required: false,
      type: String,
      default: null
    },

    name: {
      required: false,
      type: String,
      default: 'name'
    },

    label: {
      required: false,
      type: String
    },

    placeholder: {
      required: false,
      type: String,
      default: ''
    },

    errorText: {
      required: false,
      type: String
    },

    autofocus: {
      required: false,
      type: Boolean
    },

    autocomplete: {
      required: false,
      type: String,
      default: 'off'
    },

    rounded: {
      required: false,
      type: Boolean,
      default: false
    },

    multiLine: {
      required: false,
      type: Boolean,
      default: false
    },

    rows: {
      required: false,
      type: Number
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    showPasswordVisibilityButton: {
      required: false,
      type: Boolean,
      default: false
    },

    showPassword: {
      required: false,
      type: Boolean,
      default: false
    },

    showPasswordRecoveryLink: {
      required: false,
      type: Boolean,
      default: false
    },

    showCharsCounter: {
      required: false,
      type: Boolean,
      default: false
    },

    maxChars: {
      required: false,
      type: Number
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },

    onChange(event) {
      this.$emit('change', event.target.value)
    },

    validate() {
      return this.$validator.validateAll()
    },

    focus() {
      this.$refs.input.focus()
    }
  },

  computed: {
    classes() {
      return [
        { 'rounded':    this.rounded },
        { 'disabled':   this.disabled }
      ]
    }
  }
}

</script>

<style lang="scss" scoped>
.base-text-box {
  position: relative;
  margin-bottom: 20px;

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 46px;
    border-bottom: 1px solid #E6E1E1;

    .input {
      width: 100%;
      height: 100%;

      padding: 0;

      background: #fff;
      border: 0;
      outline: none;

      font-size: 16px;
      color: #1C1C1C;
    }

    .password-button {
      position: absolute;
      top: 50%;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 95px;
      height: 70%;
      padding: 0 5px;
      font-size: 12px;
      color: #797979;
      text-transform: uppercase;
      background: #fff;
      border: 1px solid #E6E1E1;
      border-radius: 25px;
      transform: translateY(-50%);
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        color: #fff;
        background-color: #afafaf;
        border-color: #afafaf;
      }
    }

    .password-link {
      color: #797979;
      font-size: 14px;
      position: absolute;
      right: 20px;
      top: 15px;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .textarea {
    width: 100%;

    margin-top: 16px;
    padding: 0;

    background: #fff;
    border: 0;
    border-bottom: 1px solid #E6E1E1;
    outline: none;

    font-size: 16px;
    color: #1C1C1C;

    resize: vertical;
  }

  &.rounded {
    .input-wrapper, .textarea {
      border: 1px solid #E6E1E1;
      border-radius: 100px;
    }

    .input, .textarea {
      padding: 20px;
    }

    .password-button {
      top: 0;
      right: 0;
      height: 100%;
      border: none;
      border-left: 1px solid #E6E1E1;
      border-radius: 0;
      transform: translateY(0);
    }
  }

  &.not-valid {
    border-color: #FF8686;
  }

  &.disabled {
    .input-wrapper, .textarea {
      border-bottom: 2px dotted;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
  }

  .error {
    color: #FF7474;
  }

  .chars-counter {
    flex-basis: 100px;
    margin-left: auto;
    text-align: right;
    color: #797979;
  }
}

</style>
