export const setAuthModalSettings = (state, settings) => {
  state.authModalSettings = settings
}

export const setAvatarUploadState = (state, { avatar, avatarData }) => {
  state.avatarUploadState = {
    uploaded: true,
    avatar,
    avatarData
  }
}

export const clearAvatarUploadState = state => {
  state.avatarUploadState = {
    uploaded: false,
    avatar: null,
    avatarData: null
  }
}
