import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/vuex/index'

Vue.use(Router)

let router = new Router({
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'home',
      redirect: {
        name: 'catalogue',
        params: {
          gender: 'female'
        }
      }
    },

    {
      path: '/publish-look/:id?',
      name: 'publishLook',
      component: () => import('@/components/PublishLook/PublishLook.vue'),
      props: (route) => {
        return {
          id: route.params.id,
          fromStudio: route.query.fromStudio
        }
      }
    },

    {
      path: '/public',
      component: () => import('@/components/SiteLayout/SiteLayout.vue'),
      redirect: {
        name: 'studio'
      },
      children: [
        {
          path: '/my-shop/:gender',
          name: 'myShop',
          component: () => import('@/pages/my-shop.vue'),
          props: true
        },

        {
          path: '/my-outfits',
          name: 'myOutfits',
          component: () => import('@/pages/my-outfits.vue')
        },

        {
          path: '/buy-service',
          component: () => import('@/components/BuyService/BuyService.vue')
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/components/Auth/Login.vue'),
          meta: {
            redirectToHomeIfAuthenticated: true
          }
        },
        {
          path: '/register/:role?',
          name: 'register',
          component: () => import('@/components/Auth/Register.vue'),
          props: true,
          meta: {
            redirectToHomeIfAuthenticated: true
          }
        },
        {
          path: '/password-recovery',
          name: 'passwordRecovery',
          component: () => import('@/components/Auth/PasswordRecovery.vue'),
          meta: {
            redirectToHomeIfAuthenticated: true
          }
        },
        {
          path: '/register-success',
          name: 'registerSuccess',
          component: () => import('@/components/Auth/RegisterSuccess.vue'),
          meta: {
            redirectToHomeIfAuthenticated: true
          }
        },
        {
          path: '/upload-avatar/:userId?',
          name: 'uploadAvatar',
          component: () => import('@/components//UploadAvatarPage.vue'),
          props: true
        },
        {
          path: '/agents-board',
          name: 'agentsBoard',
          component: () => import('@/components/AgentsBoard/AgentsBoard.vue')
        },
        {
          path: '/persons',
          name: 'persons',
          component: () => import('@/components/Persons/PersonList.vue')
        },
        {
          path: '/persons/:id',
          name: 'persons.profile',
          component: () => import('@/components/Persons/PersonProfileLayout.vue'),
          props: true,
          redirect: () => {
            return {
              name: 'persons.profile.looks'
            }
          },

          children: [
            // {
            //   path: 'general',
            //   name: 'persons.profile.general',
            //   component: require('@/components/Persons/PersonProfileGeneral.vue').default
            // },
            {
              path: 'looks',
              name: 'persons.profile.looks',
              component: () => import('@/components/Persons/PersonProfileLooks.vue')
            },
            {
              path: 'reviews',
              name: 'persons.profile.reviews',
              component: () => import('@/components/Persons/PersonProfileReviews.vue')
            }
          ]
        },
        {
          path: '/catalogue/:gender',
          name: 'catalogue',
          component: () => import('@/components/Catalogue/Catalogue.vue'),
          props: true
        },
        {
          path: '/clothing-item/:id',
          name: 'clothingItem',
          component: () => import('@/components/ClothingItem/ClothingItem.vue'),
          props: true
        },
        {
          path: '/studio',
          name: 'studio',
          component: () => import('@/components/Studio/Studio.vue'),
          props: (route) => {
            return {
              lookId: route.query.lookId,
              clientId: route.query.clientId
            }
          }
        },
        {
          path: '/feed',
          name: 'lookFeed',
          component: () => import('@/components/LookFeed/LookFeed.vue')
        },
        {
          path: '/profile/:userId/looks/:lookId',
          name: 'look',
          component: () => import('@/components/Look/Look.vue'),
          props: true
        },
        {
          path: '/my-looks/:type',
          name: 'myLooks',
          component: () => import('@/components/UserLooks/UserLooks.vue'),
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/cart',
          name: 'cart',
          component: () => import('@/components/UserCart/UserCart.vue')
        },
        {
          path: '/confirm-email/:token',
          name: 'registerConfirm',
          component: () => import('@/components/Auth/ConfirmEmail/ConfirmEmail.vue'),
          props: true
        },
        {
          path: '/reset-password/:token',
          name: 'passwordReset',
          component: () => import('@/components/Auth/PasswordReset/PasswordReset.vue'),
          props: true
        },
        {
          path: '/accept-invite/:token',
          name: 'acceptInvite',
          component: () => import('@/components/Auth/AcceptInvite/AcceptInvite.vue'),
          props: true
        },
        {
          path: '/profile-settings',
          name: 'profileSettings',
          component: () => import('@/components/ProfileSettings/Layout/Layout.vue'),

          redirect: () => {
            return {
              name: 'profileSettings.general'
            }
          },

          meta: {
            requiresAuth: true
          },

          children: [
            {
              path: 'general',
              name: 'profileSettings.general',
              component: () => import('@/components/ProfileSettings/General/General.vue')
            },
            {
              path: 'bank-details',
              name: 'profileSettings.bankDetails',
              component: () => import('@/components/ProfileSettings/BankDetails/BankDetails.vue')
            }
          ]
        },
        {
          path: '/users-permissions',
          name: 'usersPermissions',
          component: () => import('@/components/UsersPermissions/UsersPermissions.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/orders',
          name: 'clientsOrders',
          component: () => import('@/components/ClientsOrders/ClientsOrders.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/orders/:id',
          name: 'clientsOrders.item',
          component: () => import('@/components/ClientsOrders/ClientsOrdersItem/ClientsOrdersItem.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/statistics',
          name: 'statistics',
          component: () => import('@/components/ClientsOrdersStatistics.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/stylists',
          name: 'admin.stylists',
          component: () => import('@/components/Admin/Stylists/Stylists.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/stylists/new',
          name: 'admin.stylists.new',
          component: () => import('@/components/Admin/Stylists/StylistsNew/StylistsNew.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/stylists/:id',
          name: 'admin.stylists.profile',
          component: () => import('@/components/Admin/Stylists/StylistsProfile/StylistsProfile.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/clients',
          name: 'agent.clients',
          component: () => import('@/components/Agent/Clients/Clients.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/clients/new',
          name: 'agent.clients.new',
          component: () => import('@/components/Agent/Clients/ClientsNew/ClientsNew.vue')
        },
        {
          path: '/clients/:id/profile',
          name: 'agent.clients.profile',
          component: () => import('@/components/Agent/Clients/ClientsProfile/ClientsProfile.vue')
        },
        {
          path: '/clients/:id/gallery',
          name: 'agent.clients.gallery',
          component: () => import('@/components/Agent/Clients/ClientsGallery/ClientsGallery.vue')
        },
        {
          path: '/clients/:id/edit',
          name: 'agent.clients.edit',
          component: () => import('@/components/Agent/Clients/ClientsEdit/ClientsEditLayout/ClientsEditLayout.vue'),

          redirect: () => {
            return {
              name: 'agent.clients.edit.general'
            }
          },

          meta: {
            requiresAuth: true
          },

          children: [
            {
              path: 'general',
              name: 'agent.clients.edit.general',
              component: () => import('@/components/Agent/Clients/ClientsEdit/ClientsEditGeneral/ClientsEditGeneral.vue'),
            }
          ]
        },
        {
          path: '/clients/:id/looks/:type',
          name: 'agent.clients.looks',
          component: () => import('@/components/Agent/Clients/ClientsLooks/ClientsLooks.vue'),
          props: true
        },
      ]
    }
  ],

  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  let authenticated = Store.getters['auth/authenticated']

  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let redirectToHomeIfAuthenticated = to.matched.some(record => record.meta.redirectToHomeIfAuthenticated)

  if (authenticated) {
    if (redirectToHomeIfAuthenticated) {
      next({
        name: 'home'
      })
    } else {
      next()
    }
  } else {
    let locale = to.query.locale

    if (locale === 'ru' || locale === 'ru_RU') {
      localStorage.setItem('locale', 'ru_RU')
    }

    if (locale === 'en' || locale === 'en_US') {
      localStorage.setItem('locale', 'en_US')
    }

    if (requiresAuth) {
      next({
        name: 'home',
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  }
})

export default router
