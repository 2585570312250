export const getClothingItems = (state) => {
  return state.clothingItems
}

export const getClothingItemsGroupedByPartnerId = (state, getters, rootState, rootGetters) => {
  let result = []
  let groupedClothingItems = _.groupBy(state.clothingItems, 'partner_id')
  let partners = rootState.handbooks.partners

  // TODO: Getter didn't work for some reason
  // let partners = rootGetters['partners/getPartnerById'](...) didn't work

  Object.entries(groupedClothingItems).forEach(([partnerId, items]) => {
    let partner = partners.find(partner => partner.partner_id === parseInt(partnerId))

    result.push({
      partner,
      clothingItems: items
    })
  })

  return result
}

export const isItemInCart = (state) => {
  return item => {
    return state.clothingItems.some(clothingItem => clothingItem.id === item.id)
  }
}
