import Datepicker from 'vuejs-datepicker'
import getLocale from '@/services/locale'
import { en, ru } from 'vuejs-datepicker/dist/locale'

export default {
  model: {
    prop: 'selectedDate',
    event: 'change'
  },

  props: {
    rangeMode: {
      required: false,
      type: Boolean,
      default: false 
    },

    label: {
      required: false,
      type: String
    },

    selectedDate: {
      required: true,
      validator: value => typeof value === 'object'
    },

    disabledDates: {
      required: false,
      type: Object
    },

    openDate: {
      required: false,
      type: [Date, String],
      default() {
        return new Date()
      }
    },

    fullMonthName: {
      required: false,
      type: Boolean,
      default: true
    },

    highlighted: {
      required: false,
      type: Object
    },

    placeholder: {
      required: false,
      type: String
    },

    inline: {
      required: false,
      type: Boolean,
      default: false,
    },

    mondayFirst: {
      required: false,
      type: Boolean,
      default: true
    },

    clearButton: {
      required: false,
      type: Boolean,
      default: false
    },

    clearButtonIcon: {
      required: false,
      type: String,
      default: 'cross'
    },

    calendarButton: {
      required: false,
      type: Boolean,
      default: false
    },

    calendarButtonIcon: {
      required: false,
      type: String
    },

    calendarButtonIconContent: {
      required: false,
      type: String
    },

    bootstrapStyling: {
      required: false,
      type: Boolean,
      default: false
    },

    initialView: {
      required: false,
      type: String,
      default: 'day'
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    typeable: {
      required: false,
      type: Boolean,
      default: false
    },

    useUtc: {
      required: false,
      type: Boolean,
      default: false
    },

    minimumView: {
      required: false,
      type: String,
      default: 'day'
    },

    maximumView: {
      type: String,
      default: 'year'
    },

    showValidationError: {
      required: false,
      type: Boolean,
      default: false
    },

    validationRules: {
      required: false
    },

    name: {
      required: false,
      type: String,
      default: 'name'
    }
  },

  components: {
    Datepicker
  },

  data() {
    return {
      language: getLocale() === 'ru_RU' ? ru : en,
    }
  },

  methods: {
    validate() {
      return this.$validator.validateAll()
    },

    clearSelectedDate() {
      this.$refs.datepicker.clearDate()
    },
  },

  computed: {
    showErrors() {
      return this.showValidationError && this.errors.has(this.name)
    }
  }
}