<template>
  <div class="base-icon-look"
       :class="componentClasses"
       @mouseover="isHovered = true"
       @mouseleave="isHovered = false"
       @click="onClick">
    <base-icon class="icon-look"
               :class="wearWithIconClasses"
               icon="look"/>
  </div>
</template>

<script>
  export default {
    props: {
      clickable: {
        required: false,
        type: Boolean,
        default: true
      },

      active: {
        required: false,
        type: Boolean,
        default: false
      },

      showLikesNumber: {
        required: false,
        type: Boolean,
        default: false
      },

      likesNumber: {
        required: false,
        type: Number
      }
    },

    data() {
      return {
        isHovered: false
      }
    },

    methods: {
      onClick(event) {
        if (!this.clickable) {
          return
        }

        this.$emit('click', event)
      }
    },

    computed: {
      componentClasses() {
        return {
          'base-icon-like-clickable': this.clickable
        }
      },

      wearWithIconClasses() {
        return {
          'icon-look-hover': this.clickable && this.isHovered
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.base-icon-look {
  display: flex;
  align-items: center;

  &.base-icon-look-clickable {
    cursor: pointer;
  }

  .icon-look {
    fill: $primary-color;
    transition: all 0.2s;

    &.icon-look-hover {
      fill: red;
      color: #000;
    }
  }
}
</style>
