export default () => {
  initVkApi()
  initFacebookApi()
  initGooglePlusApi()
}

/**
 * Initialize Vkontakte API.
 */
function initVkApi() {
  window.vkAsyncInit = () => {
    VK.init({
      apiId: 6395781
    })
  }

  setTimeout(() => {
    let script = document.createElement('script')

    script.src = 'https://vk.com/js/api/openapi.js?154'
    script.async = true

    document.getElementById('vk_api_transport').appendChild(script)
  }, 0)
}

/**
 * Initialize Facebook API.
 */
function initFacebookApi() {
  window.fbAsyncInit = () => {
    FB.init({
      appId : 1312726612161823,
      autoLogAppEvents : true,
      xfbml : true,
      version : 'v3.0'
    })
  }

  let id = 'facebook-jssdk'

  if (document.getElementById(id)) {
    return
  }

  let script = document.createElement('script')

  script.id = id
  script.src = 'https://connect.facebook.net/en_US/sdk.js'

  document.body.prepend(script)
}

/**
 * Initialize Google Plus API.
 */
function initGooglePlusApi() {
  window.googlePlusAsyncInit = () => {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: '1073308242892-950mqacqmr7qrg4kosl9vshggksi0gth.apps.googleusercontent.com'
      })
    })
  }

  let script = document.createElement('script')

  script.src = 'https://apis.google.com/js/platform.js?onload=googlePlusAsyncInit'
  script.async = true

  document.body.prepend(script)
}