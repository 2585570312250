export const setCurrentUser = (state, user) => {
  state.authenticated = true
  state.currentUser = user
}

export const setAccessToken = (state, accessToken) => {
  state.accessToken = accessToken
}

export const setExpireToken = (state, expireToken) => {
  state.expireToken = expireToken
}

export const clearAuthInfo = (state) => {
  state.authenticated = false
  state.currentUser = false
  state.accessToken = false
  state.expireToken = false
}

export const setLocale = (state, locale) => {
  state.locale = locale
}

export const setInvitedUser = (state, invitedUser) => {
  state.invitedUser = invitedUser
}
