export default {
  props: {
    placeholder: {
      required: false,
      type: String,
      default: ''
    },

    autofocus: {
      required: false,
      type: Boolean
    },

    value: {
      required: false,
      type: String,
      default: ''
    }
  },

  data() {
    return {
      inputValue: this.value
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },

    clear() {
      this.inputValue = ''
      this.$emit('input', this.inputValue)
    },

    focus() {
      this.$refs.input.focus()
    }
  }
}