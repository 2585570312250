import lookTransformer from '@/services/transformers/lookTransformer'
import dataURLtoBlob from 'blueimp-canvas-to-blob'

const baseURL = 'profiles/:userId/looks'

export default {

  /**
   * Get look list.
   *
   * @param userId
   * @param params
   * @returns Promise
   */
  get(userId, params = {}) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.get(endpointURL, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, data, { items: data.items.map(lookTransformer) })
      }
    }).then(response => response.data)
  },

  /**
   * Get specific look.
   *
   * @param userId
   * @param id
   * @param params
   * @returns Promise
   */
  find(userId, id, params = {}) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.get(`${endpointURL}/${id}`, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, lookTransformer(data))
      }
    }).then(response => response.data)
  },

  /**
   * Store new look.
   *
   * @param userId
   * @param params
   * @returns Promise
   */
  store(userId, params) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.post(endpointURL, JSON.stringify(params)).then(response => response.data)
  },

  /**
   * Update existing look.
   *
   * @param userId
   * @param id
   * @param params
   * @returns Promise
   */
  update(userId, id, params) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.patch(`${endpointURL}/${id}`, JSON.stringify(params)).then(response => response.data)
  },

  /**
   * Store look preview.
   *
   * @param userId
   * @param id
   * @param base64Picture
   * @returns Promise
   */
  storePreview(userId, id, base64Picture) {
    let endpointURL = baseURL.replace(':userId', userId)

    let formData = new FormData
    formData.append('image', dataURLtoBlob(base64Picture))

    return axios.post(`${endpointURL}/${id}/preview`, formData).then(response => response.data)
  },

  /**
   * Store edited image.
   *
   * @param dataUrl
   * @returns {*}
   */
  storeEditedImage(dataUrl) {
    let endpointURL = `profiles/me/operating_images`

    let formData = new FormData
    formData.append('image', dataURLtoBlob(dataUrl))

    return axios.post(endpointURL, formData).then(response => response.data)
  },

  /**
   * Store user clothing picture.
   *
   * Used when user uploads his own pictures to artboard.
   * Same URL endpoint as for storeEditedImage, but
   * this is different method in terms of logic.
   *
   * @param dataUrl
   * @returns {*}
   */
  storeUserClothingPicture(dataUrl) {
    let endpointURL = `profiles/me/operating_images`

    let formData = new FormData
    formData.append('image', dataURLtoBlob(dataUrl))

    return axios.post(endpointURL, formData).then(response => {
      return response.data
    })
  },

  /**
   * Store new look by photo.
   *
   * @param userId
   * @param formData
   * @returns Promise
   */
  storeLooksByPhoto(userId, formData) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.post(endpointURL, formData).then(response => response.data)
  },

  /**
   * Get current profile.
   * @returns Promise
   */
  getCurrentProfile() {
    let endpointURL = `profiles/me/`

    return axios.get(endpointURL).then(response => response.data)
  },

  /**
   * Remove existing look.
   *
   * @param userId
   * @param id
   * @returns Promise
   */
  remove(userId, id) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.delete(`${endpointURL}/${id}`).then(response => response.data)
  },

  /**
   * Like look.
   *
   * @param userId
   * @param id
   * @returns {*|AxiosPromise<T>}
   */
  like(userId, id) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.post(`${endpointURL}/${id}/likes`)
  },

  /**
   * Remove like.
   *
   * Notice that this is not dislike.
   *
   * @param userId
   * @param id
   */
  removeLike(userId, id) {
    let endpointURL = baseURL.replace(':userId', userId)

    return axios.delete(`${endpointURL}/${id}/likes`)
  }
}