export default {
  props: {
    value: {
      required: true,
      type: Boolean
    },

    label: {
      required: false,
      type: String
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    selectableTitle: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isChecked: this.value
    }
  },

  methods: {
    onChange() {
      this.$emit('input', this.isChecked)
    },

    onFocus(event) {
      this.isActive = true
      this.$emit('focus', event)
    },

    onBlur(event) {
      this.isActive = false
      this.$emit('blur', event)
    }
  },

  computed: {
    classes() {
      return [
        { 'is-checked':  this.isChecked },
        { 'is-disabled': this.disabled },
        { 'selectable-title': this.selectableTitle }
      ];
    }
  },

  watch: {
    value() {
      this.isChecked = !!this.value
    }
  },
}