<template>
  <div id="app" class="app">
    <router-view v-if="loaded" />
    <div class="loader"
         ref="loader"
         v-else></div>
  </div>
</template>

<script>
import { bootstrap } from './bootstrap'
import { authenticated, registerEphemeralUser } from '@/services/auth'
import lottie from 'lottie-web'

export default {
  name: 'App',

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'DNA2STYLE',
    // all titles will be injected into this template
    titleTemplate: '%s | DNA2STYLE'
  },

  data () {
    return {
      loaded: false
    }
  },

  mounted () {
    lottie.loadAnimation({
      container: this.$refs.loader, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/DNA_progress.json' // the path to the animation json
    });
  },

  async created () {
    if (!authenticated()) {
      const response = await registerEphemeralUser()

      this.setCurrentUser(response.user)
      this.setAuthorizationTokens({
        accessToken: response.access_token,
        expireToken: response.expire_token
      })

      axios.defaults.headers['X-Lamb-Auth-Token'] = response.access_token
    }

    await bootstrap(authenticated())

    this.loaded = true

    this.redirectToSavedRoute()
  },

  methods: {
    /**
     * This is made to properly redirect user to the home page when page reloads after logging in
     */
    redirectToSavedRoute() {
      let routeToRedirect = JSON.parse(localStorage.getItem('routeToRedirect'))

      if (!routeToRedirect) {
        return
      }

      this.$router.push(routeToRedirect)

      localStorage.removeItem('routeToRedirect')
    },

    ...mapActions('auth', [
      'setCurrentUser',
      'setAuthorizationTokens',
    ])
  }
}
</script>

<style src="./assets/scss/app.scss" lang="scss"></style>
<style lang="scss" scoped>
 .loader {
   position: absolute;
   top: 50%;
   right: 50%;
   transform: translate(50%, -50%);
   width: 500px;
   height: 500px;
 }
</style>
