import profileTransformer from './profileTransformer.js'

export default review => {
  return {
    comment:     review.review,
    createdAt:   review.time_created,
    updatedAt:   review.time_updated,
    rating:      review.rating,
    authorId:    review.author_id,
    author:      profileTransformer(review.author)
  }
}