import Popper from 'vue-popperjs'
import Vue from 'vue'
import store from './vuex/index'
import handbooksTransformer from '@/services/transformers/handbooksTransformer'

const loadHandbooks = () => {
  return axios.get('handbooks', {
    transformResponse: (data) => {
      return Object.assign({}, handbooksTransformer(data))
    }
  }).then(response => {
    let handbooks = response.data

    store.commit('clothingCategories/setClothingCategories',       handbooks.clothing_categories)
    store.commit('clothingSubCategories/setClothingSubCategories', handbooks.clothing_subcategories)
    store.commit('currencies/setCurrencies',                       handbooks.currencies)
    store.commit('archetypes/setArchetypes',                       handbooks.archetypes)
    store.commit('colorTypes/setColorTypes',                       handbooks.color_types)
    store.commit('figureTypes/setFigureTypes',                     handbooks.figure_types)
    store.commit('partners/setPartners',                           handbooks.partners)
    store.commit('clothingSizeMetrics/setClothingSizeMetrics',     handbooks.clothing_size_metrics)
    store.commit('clothingSizes/setClothingSizes',                 handbooks.clothing_sizes)
    store.commit('countries/setCountries',                         handbooks.countries)
    store.commit('timeZones/setTimeZones',                         handbooks.timezones)
    store.commit('lookContexts/setLookContexts',                   handbooks.look_contexts)
  })
}

const updateCurrentUser = () => {
  return store.dispatch('auth/updateCurrentUser')
}

export const loadSvg = () => {
  let context = require.context('@/assets/svg', true, /\.svg$/)
  context.keys().forEach(context)
}

export const loadComponents = () => {
  let requireComponent = require.context("@/components/Base/", true, /\.vue$/)

  requireComponent.keys().forEach((fileName) => {
    let baseComponentConfig = requireComponent(fileName)
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig

    let baseComponentName = baseComponentConfig.name || (
            fileName
                .replace(/^.+\//, '')
                .replace(/\.\w+$/, '')
        )

    Vue.component(baseComponentName, baseComponentConfig)
  })

  Vue.component('popper', Popper)
}

export const loadIcons = () => {
  let requireComponent = require.context("@/components/Icons/", true, /\.vue$/)

  requireComponent.keys().forEach((fileName) => {
    let baseComponentConfig = requireComponent(fileName)
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig

    let baseComponentName = baseComponentConfig.name || (
      fileName
        .replace(/^.+\//, '')
        .replace(/\.\w+$/, '')
    )

    Vue.component(baseComponentName, baseComponentConfig)
  })
}

export const bootstrap = isAuthenticated => {
  let requests = [
    // loadHandbooks(),
    store.dispatch('handbooks/loadClothingCategories'),
    store.dispatch('handbooks/loadClothingSubCategories'),
    store.dispatch('handbooks/loadCurrencies'),
    store.dispatch('handbooks/loadArchetypes'),
    store.dispatch('handbooks/loadColorTypes'),
    store.dispatch('handbooks/loadFigureTypes'),
    store.dispatch('handbooks/loadPartners'),
    store.dispatch('handbooks/loadClothingSizeMetrics'),
    store.dispatch('handbooks/loadCountries'),
    store.dispatch('handbooks/loadTimezones'),
    store.dispatch('handbooks/loadLookContexts'),
  ]

  if (isAuthenticated) {
    requests.push(updateCurrentUser())
  }

  return axios.all(requests)
}
