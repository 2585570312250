import Vue from 'vue'
import Vuex from 'vuex'
import system from './modules/system/store'
import auth from './modules/auth/store'
import studio from './modules/studio/store'
import cart from './modules/cart/store'
import looks from './modules/looks/store'
import handbooks from './modules/handbooks'
import persist from './plugins/persist'

Vue.use(Vuex)

const debug = Vue.config.NODE_ENV !== 'production'

const state = {
  loaded: false,
  routeIsLoading: false
}

const getters = {
  appIsLoaded(state) {
    return state.loaded
  },

  routeIsLoading(state) {
    return state.routeIsLoading
  }
}

const mutations = {
  setLoaded(state, loaded) {
    state.loaded = loaded
  },

  setRouteIsLoading(state, routeIsLoading) {
    state.routeIsLoading = routeIsLoading
  }
}

const actions = {
  setLoaded({ commit }, loaded) {
    commit('setLoaded', loaded)
  },

  setRouteIsLoading({ commit }, routeIsLoading) {
    commit('setRouteIsLoading', routeIsLoading)
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,

  modules: {
    handbooks,
    system,
    auth,
    studio,
    cart,
    looks,
  },

  plugins: [
    persist
  ],

  strict: debug
})
