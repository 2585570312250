export default {
  props: {
    width: {
      required: false,
      type: [Number, String],
      default: 16
    },

    height: {
      required: false,
      type: [Number, String],
      default: 16
    },

    viewBox: {
      required: false,
      type: String,
      default: '0 0 100 100'
    },

    color: {
      required: false,
      type: String,
      default: '#000'
    },

    hoverColor: {
      type: String,
      default: 'currentColor'
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      disabledStateColor: '#B9B9B9',
      isHover: false
    }
  },

  computed: {
    fillColor () {
      if (this.disabled) {
        return this.disabledStateColor
      }

      if (this.isHover) {
        return this.hoverColor
      }

      return this.color
    }
  }
}
