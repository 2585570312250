import Vue from 'vue'
import { isValidNumber } from 'libphonenumber-js'
import ru from '@/lang/validation/ru_RU.js'
import en from '@/lang/validation/en_US.js'

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from 'vee-validate/dist/vee-validate.full'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('email_unique', (value, args) => {
  return axios.get('check_credential_available', { params: {
    check_type: 'email',
    email: value
  }}).then(response => response.data.check_result)
      .catch(error => false) // TODO: Is it best practice?
})

extend('nickname_unique', (value, args) => {
  return axios.get('check_credential_available', { params: {
    check_type: 'nick_name',
    nick_name: value
  }}).then(response => response.data.check_result)
      .catch(error => false) // TODO: Is it best practice?
})

extend('phonenumber_unique', (value, args) => {
  return axios.get('check_credential_available', { params: {
    check_type: 'phone_number',
    phone_number: value,
    phone_number_country_code: args[0]
  }}).then(response => response.data.check_result)
      .catch(error => false) // TODO: Is it best practice?
})

extend('valid_phone', (value, args) => {
  let countryCode = args[0] ? args[0].toUpperCase() : ''

  return isValidNumber(value, countryCode)
})

extend('instagram_account', value => {
  let instagramRegex = new RegExp('^[\\w.\\-_]+$')

  return instagramRegex.test(value)
})

extend('web_site', value => {
  let websiteRegex = new RegExp('^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$')

  return websiteRegex.test(value)
})
