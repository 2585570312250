import profileTransformer from '@/services/transformers/profileTransformer'
import lookTransformer from '@/services/transformers/lookTransformer'
import reviewTransformer from '@/services/transformers/reviewTransformer'

const baseURL = 'profiles'

export default {
  get(params = {}) {
    return axios.get(`${baseURL}`, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, data, { items: data.items.map(profileTransformer) })
      }
    }).then(response => response.data)
  },

  /**
   * Get specific profile.
   *
   * @param userId
   * @param params
   * @returns Promise
   */
  find(userId, params = {}) {
    return axios.get(`${baseURL}/${userId}`, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, profileTransformer(data))
      }
    }).then(response => response.data)
  },

  /**
   * Update multiple profiles at the same time.
   *
   * @param users
   * @returns Promise
   */
  updateMultiple(users) {
    return axios.patch(`${baseURL}`, { profiles: users }).then(response => response.data)
  },

  /**
   * Update specific profile.
   *
   * @param userId
   * @param params
   * @returns {*}
   */
  update(userId, params) {
    return axios.patch(`${baseURL}/${userId}`, JSON.stringify(params)).then(response => response.data)
  },

  /**
   * Create new profile.
   *
   * @param params
   * @returns Promise
   */
  store(params) {
    return axios.post(`${baseURL}`, JSON.stringify(params)).then(response => response.data)
  },

  /**
   * Follow user.
   *
   * @param userId
   * @returns {AxiosPromise<T>|*}
   */
  follow(userId) {
    return axios.post(`${baseURL}/me/followings/${userId}`)
  },

  /**
   * Unfollow user
   *
   * @param userId
   */
  unfollow(userId) {
    return axios.delete(`${baseURL}/me/followings/${userId}`)
  },

  /**
   * Get user looks.
   *
   * @param userId
   * @param params
   */
  getLooks(userId, params = {}) {
    return axios.get(`${baseURL}/${userId}/looks`, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, { items: data.items.map(lookTransformer) })
      }
    }).then(response => response.data)
  },

  /**
   * Get user invitations.
   *
   * @returns Promise
   */
  getInvites() {
    return axios.get('invites').then(response => response.data.items)
  },

  /**
   * Get user reviews.
   *
   * @param userId
   */
  getReviews(userId) {
    let endpointURL = `${baseURL}/${userId}/reviews`

    return axios.get(endpointURL, {
      transformResponse: (data) => {
        return Object.assign({}, data, { items: data.items.map(reviewTransformer) })
      }
    }).then(response => response.data)
  },

  /**
   * Get my review.
   *
   * @param userId
   */
  reviewLeft(userId) {
    let endpointURL = `${baseURL}/${userId}/reviews/me`

    return axios.get(endpointURL, {
      transformResponse: (data) => {
        return Object.assign({}, reviewTransformer(data))
      }
    }).then(response => response.data).catch(error => false)
  },

  /**
   * Store user review.
   *
   * @param userId
   * @param rating
   * @param review
   */
  storeReview(userId, rating, review) {
    let endpointURL = `${baseURL}/${userId}/reviews`
    let params = {
      rating,
      review
    }

    return axios.post(endpointURL, JSON.stringify(params)).then(response => response.data)
  },

  /**
   * Send message.
   *
   * @param userId
   * @param body
   */
  sendMessage(userId, body) {
    let endpointURL = `${baseURL}/${userId}/messages`
    let params = {
      body
    }

    return axios.post(endpointURL, JSON.stringify(params)).then(response => response.data)
  }
}